import { createContext } from 'react';

import { useSafeContext } from '@aspendental/shared-utils/react';

import { Maybe } from '@/types';
import { brandFolderCDNHost, contentfulCDNHost, imageResponsiveSizes } from '@/constants';

const imageOptimizerMap: Record<string, (url: URL, width?: string | number, quality?: string | number) => string> = {
	[contentfulCDNHost]: (url, width, quality) => {
		if (width) {
			url.searchParams.set('w', `${width}`);
		}
		if (quality) {
			url.searchParams.set('q', `${quality}`);
		}
		url.searchParams.set('fm', `webp`);
		return url.toString();
	},
	[brandFolderCDNHost]: (url, width, quality) => {
		if (width) {
			url.searchParams.set('width', `${width}`);
		}
		if (quality) {
			url.searchParams.set('quality', `${quality}`);
		}
		url.searchParams.set('auto', 'webp');
		return url.toString();
	},
};

/**
 * Modifies the URL to request the image in webp format from the Contentful Image and brand folder API
 * svg file images will not be optimized
 * @see https://www.contentful.com/developers/docs/references/images-api/#/reference/changing-formats
 * @see https://help.smartsheet.com/articles/2482927-Embedding-Assets-with-Smart-CDN
 * @export
 * @param {Maybe<string>} [urlStr]
 * @return {*}  {string}
 */
export function optimizeImageUrl(urlStr?: Maybe<string>, width?: string | number, quality?: string | number): string {
	if (urlStr) {
		try {
			const url = new URL(urlStr);
			// remove all search searchParams for svg files
			const basePath = url.href.split('?')[0];
			if (basePath.endsWith('.svg')) {
				return basePath;
			}
			return imageOptimizerMap[url.host](url, width, quality);
		} catch (e) {
			// do nothing we might want to log images that are not be optimized
		}
	}
	return urlStr ?? '';
}

export function optimizeSrcSet(
	url: string | undefined | null,
	quality?: string | number,
	imageSizes: (number | string | undefined)[] = imageResponsiveSizes
) {
	if (!url) {
		return '';
	}
	return imageSizes
		.map((width) => {
			const size = width ? ` ${width}w` : '';
			return `${optimizeImageUrl(url, width, quality)}${size}`;
		})
		.join(' , ');
}

// ImagePriorityContext is a context that provide info if we need to preload
const ImagePriorityContext = createContext(Infinity);
ImagePriorityContext.displayName = 'ImagePriority';
export { ImagePriorityContext };

export function useIsHighPriorityImage() {
	const ImagePriority = useSafeContext(ImagePriorityContext) ?? Infinity;
	return ImagePriority < 2;
}
