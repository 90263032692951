import { Box, Typography } from '@mui/material';

import TAGLink from '@/components/TAGLink';
import { AdFooterFooterPolicyGroupCollection, Maybe, NavigationLink, Page } from '@/types/generated';
import { resolveLinkTitle, resolveLinkUrl } from '@/components/FooterLite/FooterLite.helpers';

import { policyLinkStyles, getPolicyGroupStyle } from './Footer.styles';

interface IFooterPolicyProps {
	policyGroup: Maybe<AdFooterFooterPolicyGroupCollection>;
}
const FooterPolicy = ({ policyGroup }: IFooterPolicyProps) => {
	const PolicyLinks = () => {
		const groupItems = policyGroup?.items;

		return (
			<Box data-test-id="section_footer_policylinks" sx={getPolicyGroupStyle}>
				{groupItems &&
					groupItems.map(
						(link: Maybe<NavigationLink | Page> | undefined) =>
							link && (
								<TAGLink
									key={link.sys.id}
									href={resolveLinkUrl(link)}
									openInNewTab={(link as NavigationLink)?.openInNewTab || false}
									dataTestId={`policy_link_${link.sys.id}`}
								>
									<Typography color="text.light" sx={policyLinkStyles} title={resolveLinkTitle(link)}>
										{resolveLinkTitle(link)}
									</Typography>
								</TAGLink>
							)
					)}
			</Box>
		);
	};
	return (
		<Box
			data-test-id="section_footer_policy"
			flexDirection={{ xs: 'row', md: 'column' }}
			display={'flex'}
			justifyContent={'space-between'}
			gap={2}
		>
			<PolicyLinks />
		</Box>
	);
};

export default FooterPolicy;
