import { Breadcrumbs, useTheme, Typography } from '@mui/material';

import TAGLink from '@/components/TAGLink';
import { Maybe, Page } from '@/types/generated';
import TAGSvgIcon from '@/components/TAGSvgIcon';

export interface IBreadCrumbs {
	isFooterLite?: boolean;
	breadcrumbsData: Page;
}

const BreadCrumbs = ({ breadcrumbsData, isFooterLite = false, ...props }: IBreadCrumbs) => {
	const { breadcrumbsCollection, breadcrumbTitle } = breadcrumbsData;
	const breadcrumbs = breadcrumbsCollection?.items;
	const theme = useTheme();

	if (!breadcrumbs || breadcrumbs?.length <= 0) {
		return null;
	}

	const breadcrumbsRender = {
		breadcrumbTitle: breadcrumbTitle && (
			<Typography
				variant="bodySmallBook"
				color={isFooterLite ? 'text.interactive' : 'text.light'}
				data-test-id="breadcrumb_current_page_title"
			>
				{breadcrumbTitle}
			</Typography>
		),
		breadcrumbsList:
			Array.isArray(breadcrumbs) &&
			breadcrumbs.map((page: Maybe<Page>, index: number) => (
				<TAGLink key={`breadcrumb-${index}`} href={page?.path || ''} dataTestId={`breadcrumb_link_${index}`}>
					<Typography
						color={isFooterLite ? 'text.interactive' : 'text.light'}
						variant="bodySmallBook"
						title={page?.breadcrumbTitle || ''}
					>
						{page?.breadcrumbTitle}
					</Typography>
				</TAGLink>
			)),
	};

	return (
		<Breadcrumbs
			{...props}
			separator={
				<TAGSvgIcon
					icon="ChevronRightFunc"
					size={12}
					htmlColor={isFooterLite ? theme.palette.text.interactive : theme.palette.text.light}
				/>
			}
			aria-label="breadcrumbs"
			data-test-id="breadcrumbs_container"
		>
			{breadcrumbsRender.breadcrumbsList}
			{breadcrumbsRender.breadcrumbTitle}
		</Breadcrumbs>
	);
};

export default BreadCrumbs;
