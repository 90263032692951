const sleep = (time = 0) =>
	new Promise<void>((resolve) => {
		setTimeout(resolve, time);
	});

type ESMModule = {
	default: unknown;
	[x: string]: unknown;
};

export function breakUpLongTask<Module extends ESMModule, Key extends keyof Module>(
	importFn: () => Promise<Module>,
	key: Key = 'default' as Key
) {
	return async (): Promise<Module[Key]> => {
		if (globalThis.window) {
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (globalThis?.window?.scheduler?.yield) {
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
				await globalThis.window.scheduler.yield();
			}
			await sleep();
		}
		const mod = await importFn();
		return mod[key];
	};
}
